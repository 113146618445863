import React, { Component } from "react";
import { toHalfWidth } from "../../utility/utility";
import QRCode from "qrcode.react";
import "./card.css";

function extractAlphaNumeric(text) {
  const match = text.match(/([A-Za-z]*\d+)/);
  return match ? match[1] : "";
}

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textEditorOpened: {
        productId: false,
        name: false,
        price: false,
        size: false,
        ct: false,
        material: false,
        memo: false,
        info: false,
        deadline: false,
        children: [],
      },
      openedBoxes: {},
      tempImages: {},
      tempValue: {
        productId: this.props.productId,
        name: this.props.name,
        price: this.props.price,
        size: `Size : ${this.props.size}`,
        ct: `CT / ${this.props.ct + ""}${
          this.props.catalogUnit ? `(${this.props.catalogUnit})` : ""
        }`,
        material: this.props.material,
        memo: this.props.memo,
        info: this.props.info,
        deadline: this.props.deadline,
        children: [],
      },
    };
  }

  handleChangeInTempImageUrl = (value, id) => {
    let tempImages = { ...this.state.tempImages };
    tempImages[id] = value;
    this.setState({ tempImages });
  };

  toggleTempImgUrlBox = (id) => {
    let openedBoxes = { ...this.state.openedBoxes };
    openedBoxes[id] = !openedBoxes[id];
    this.setState({ openedBoxes });
  };

  closeTempImgUrlBox = (productId, branchId) => {
    let id = productId + branchId;
    if (!!this.state.tempImages[id] && !!this.state.tempImages[id].trim()) {
      this.props.adjustDataFn({
        value: {
          imgUrl: this.state.tempImages[id],
          productId,
          branchId,
        },
        type: "imgUrl",
      });
    }
    this.toggleTempImgUrlBox(id);
  };

  showReWriterInput(textType) {
    return (
      <div className="wrapper-rewriter">
        <input
          className="input-rewriter"
          type="text"
          placeholder={`入力してください`}
          value={this.state.tempValue[textType]}
          onChange={(e) => this.handleChange(e.target.value, textType)}
        />
        <button
          className="btn-sm btn-icon btn-rewriter"
          onClick={() => {
            this.toggleTextEditor(textType);
            this.props.handleChangeFn(textType, this.state.tempValue[textType]);
          }}
        >
          保存
        </button>
      </div>
    );
  }

  handleChange = (value, textType) => {
    let tempValue = { ...this.state.tempValue };
    tempValue[textType] = value;
    this.setState({ tempValue });
  };

  toggleTextEditor = (textType) => {
    let textEditorOpened = { ...this.state.textEditorOpened };
    textEditorOpened[textType] = !textEditorOpened[textType];
    this.setState({ textEditorOpened });
  };

  render() {
    let childrenWithUnitSize,
      self = this,
      branchId = this.props.branchId,
      material = this.props.material,
      patterns = this.props.patterns,
      size = this.props.size,
      // eslint-disable-next-line
      info = this.props.info,
      regular = this.props.regular,
      selected = this.props.selected;

    if (this.props.cardType === "type3" && !this.props.hideStock) {
      if (this.props.children.length > 0 && !!this.props.children[0].color) {
        branchId = this.props.children
          .sort((a, b) => {
            if (a.color < b.color) {
              return -1;
            }
            if (a.color > b.color) {
              return 1;
            }
            return 0;
          })
          .map((c) => c.color)
          .filter(function(value, index, array) {
            return array.indexOf(value) === index;
          })
          .join(",");
      }
      if (this.props.children.length > 0 && !!this.props.children[0].unitSize) {
        if (!!this.props.children[0].color) {
          let data = this.props.children.map((child) => {
            child.id = extractAlphaNumeric(child.id);
            child.branchid = [];
            child.branchid.push(child.color);
            return child;
          });
          childrenWithUnitSize = Object.values(
            data.reduce((acc, current) => {
              const { id, branchid, color, ...rest } = current;
              acc[id] = acc[id] || { id, branchid: [], ...rest };
              acc[id].branchid.push(color);
              return acc;
            }, {})
          );
        }

        if (
          this.props.children.length > 0 &&
          !!this.props.children[0].pattern
        ) {
          let data = this.props.children.map((child) => {
            child.id = parseInt(child.id);
            child.branchid = [];
            child.branchid.push(child.pattern);
            return child;
          });
          childrenWithUnitSize = Object.values(
            data.reduce((acc, current) => {
              const { id, branchid, pattern, ...rest } = current;
              acc[id] = acc[id] || { id, branchid: [], ...rest };
              acc[id].branchid.push(pattern);
              return acc;
            }, {})
          );

          if (
            this.props.children.length > 1 &&
            !!this.props.children[0].material &&
            material !== this.props.children[0].material
          ) {
            material = this.props.children[0].material;
          }
        }
      } else {
        if (
          this.props.children.length > 0 &&
          !!this.props.children[0].pattern
        ) {
          patterns = "";
          size = "";

          this.props.children.forEach((child, index) => {
            if (index !== 0) patterns += "・";
            patterns += child.pattern;

            if (
              index !== 0 &&
              child.size === this.props.children[index - 1].size
            )
              return;
            if (!!child.size) {
              if (index !== 0) size += "\n・";
              size += `(${child.pattern})` + child.size;
            }
          });
        }

        if (
          this.props.children.length > 1 &&
          !!this.props.children[0].info &&
          this.props.children[0].info !== this.props.children[1].info
        ) {
          info = "";
          this.props.children.forEach((child, index) => {
            if (
              index !== 0 &&
              child.info === this.props.children[index - 1].info
            )
              return;
            if (index !== 0) info += "　　";
            if (!!child.pattern) {
              info += `${child.patttern}:${child.info}`;
            } else if (!!child.color) {
              info += `${child.color}:${child.info}`;
            }
          });
        }

        if (
          this.props.children.length > 1 &&
          !!this.props.children[0].material &&
          this.props.children[0].material !== this.props.children[1].material
        ) {
          material = "";
          this.props.children.forEach((child, index) => {
            if (
              index !== 0 &&
              child.material === this.props.children[index - 1].material
            )
              return;
            if (index !== 0) material += "　　";
            if (!!child.pattern) {
              material += `${child.patttern}:${child.material}`;
            } else if (!!child.color) {
              material += `${child.color.trim()}:${child.material.trim()}`;
            }
          });
        }
      }

      return (
        <div
          className={`card img-thumbnail ${
            selected ? "selected-for-print" : ""
          } ${this.props.smallerText ? "smaller-text-selected" : ""} `}
        >
          {this.props.viewMode && (
            <div className="hover-show btn-arrow-wrapper ">
              <button
                className="btn btn-arrow"
                onClick={() => this.props.changeSelectedCardPositionFn("up")}
              >
                <i className="fa fa-arrow-circle-up" />
              </button>
              <button
                className="btn btn-arrow"
                onClick={() => this.props.changeSelectedCardPositionFn("down")}
              >
                <i className="fa fa-arrow-circle-down" />
              </button>
            </div>
          )}
          <button
            className={`btn hover-show btn-selected ${
              selected ? "active-for-print" : ""
            }`}
            onClick={(event) => {
              let target = event.currentTarget
                ? event.currentTarget
                : event.srcElement;
              let parentNode = target.parentNode;
              if (target.className.indexOf("active-for-print") === -1) {
                target.className += " active-for-print";
                parentNode.className += " selected-for-print";
                this.props.selectItemForPrintFn();
              } else {
                target.className = target.className.replace(
                  new RegExp("active-for-print", "g"),
                  ""
                );
                parentNode.className = parentNode.className.replace(
                  new RegExp("selected-for-print", "g"),
                  ""
                );
                this.props.deselectItemForPrintFn();
              }
            }}
          >
            <i className="icon-btn fa fa-check" />
          </button>
          <button
            className={`btn hover-show btn-selected btn-break-point ${
              this.props.breakpoint ? "breakpoint-active" : ""
            }`}
            onClick={(event) => {
              let target = event.currentTarget
                ? event.currentTarget
                : event.srcElement;
              let parentNode = target.parentNode;
              let cardNode = target.parentNode.parentNode;
              if (target.className.indexOf("breakpoint-active") === -1) {
                target.className += " breakpoint-active";
                parentNode.className += " breakpoint-selected";
                cardNode.className += " breakpoint-print";
                this.props.selectBreakpointForPrintFn();
              } else {
                target.className = target.className.replace(
                  new RegExp("breakpoint-active", "g"),
                  ""
                );
                parentNode.className = parentNode.className.replace(
                  new RegExp("breakpoint-selected", "g"),
                  ""
                );
                cardNode.className = cardNode.className.replace(
                  new RegExp("breakpoint-print", "g"),
                  ""
                );
                this.props.deselectBreakPointForPrintFn();
              }
            }}
          >
            <i className="icon-btn fa fa-file" />
          </button>
          <button
            className={`btn hover-show btn-selected btn-smaller-text ${
              this.props.smallerText ? "smaller-text-active" : ""
            }`}
            onClick={(event) => {
              let target = event.currentTarget
                ? event.currentTarget
                : event.srcElement;
              let parentNode = target.parentNode;
              if (target.className.indexOf("smaller-text-active") === -1) {
                target.className += " smaller-text-active";
                parentNode.className += " smaller-text-selected";
                this.props.selectSmallerTextFn();
              } else {
                target.className = target.className.replace(
                  new RegExp("smaller-text-active", "g"),
                  ""
                );
                parentNode.className = parentNode.className.replace(
                  new RegExp("smaller-text-selected", "g"),
                  ""
                );
                this.props.deselectSmallerTextFn();
              }
            }}
          >
            <i className="icon-btn fa fa-font" />
          </button>
          <div className="card-img-top_type3">
            <img
              className="card-img"
              src={this.props.imgUrl}
              alt={this.props.name}
              onLoad={(e) => this.props.imgLoadDetectFn(e)}
              onError={(e) => {
                e.hasError = true;
                e.errorMsg = this.props.imgUrl;

                this.props.imgLoadDetectFn(e);
              }}
            />
          </div>
          <div className="card-body row">
            <div className="box-left col-3">
              <QRCode
                fgColor="rgb(80,80,80)"
                size={80}
                value={
                  this.props.pageUrl +
                  (this.props.pageUrl.indexOf("?") > -1
                    ? "&access_mode=qr"
                    : "?access_mode=qr")
                }
              />
            </div>
            <div className="box-right col-9">
              <div className="row border-bottom">
                {this.state.textEditorOpened.name ? (
                  this.showReWriterInput("name")
                ) : (
                  <h5
                    onClick={() => this.toggleTextEditor("name")}
                    className="card-title "
                  >
                    {this.state.tempValue.name}
                  </h5>
                )}
              </div>

              {this.props.children.length > 0 &&
              !!this.props.children[0].unitSize ? (
                (childrenWithUnitSize
                  ? childrenWithUnitSize
                  : this.props.children
                ).map(function(child, index) {
                  return (
                    <div className="row-main" key={`child-${index}`}>
                      <div className="row row-primary">
                        <div className="text-box">{child.unitSize}</div>
                        <span
                          className={`text-title text-title-primary ${
                            regular ? "text-color-primary" : ""
                          } ${
                            child.id.toString().slice(0, 2) === "16"
                              ? "text-color-emphasize"
                              : ""
                          }`}
                        >
                          {child.id}
                        </span>
                        {self.props.hidePrice ? (
                          ""
                        ) : (
                          <span className="text-price">
                            {parseInt(child.price).toLocaleString("JP", {
                              style: "currency",
                              currency: "JPY",
                            })}
                            <span className="text-optional-msg">
                              {self.props.lang === "ja"
                                ? "(税別)"
                                : "(Tax excluded)"}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="row row-secondary">
                        <span className="text-optional">
                          Size : {child.size}
                        </span>
                      </div>
                      <div className="row row-secondary">
                        <span className="text-optional">
                          CT / {child.ct}({child.catalogUnit})
                        </span>
                        <span className="text-optional">{child.deadline}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row-main">
                  <div className="row row-primary">
                    <div className="text-box" />
                    {this.state.textEditorOpened.productId ? (
                      this.showReWriterInput("productId")
                    ) : (
                      <span
                        className={`text-title text-title-primary ${
                          regular ? "text-color-primary" : ""
                        } ${
                          this.state.tempValue.productId
                            .toString()
                            .slice(0, 2) === "16"
                            ? "text-color-emphasize"
                            : ""
                        }`}
                        onClick={() => this.toggleTextEditor("productId")}
                      >
                        {this.state.tempValue.productId}
                      </span>
                    )}

                    {this.props.hidePrice ? (
                      ""
                    ) : (
                      <span className="text-price">
                        {this.state.textEditorOpened.price ? (
                          this.showReWriterInput("price")
                        ) : (
                          <span onClick={() => this.toggleTextEditor("price")}>
                            {parseInt(
                              this.state.tempValue.price
                            ).toLocaleString("JP", {
                              style: "currency",
                              currency: "JPY",
                            })}
                          </span>
                        )}

                        <span className="text-optional-msg">
                          {this.props.lang === "ja"
                            ? "(税別)"
                            : "(Tax excluded)"}
                        </span>
                      </span>
                    )}
                  </div>
                  <div className="row row-secondary">
                    {this.state.textEditorOpened.size ? (
                      this.showReWriterInput("size")
                    ) : (
                      <span
                        onClick={() => this.toggleTextEditor("size")}
                        className="text-optional"
                      >
                        {this.state.tempValue.size}
                      </span>
                    )}
                  </div>
                  <div className="row row-secondary">
                    {this.state.textEditorOpened.ct ? (
                      this.showReWriterInput("ct")
                    ) : (
                      <span
                        onClick={() => this.toggleTextEditor("ct")}
                        className="text-optional"
                      >
                        {this.state.tempValue.ct}
                      </span>
                    )}
                    {this.state.textEditorOpened.deadline ? (
                      this.showReWriterInput("deadline")
                    ) : (
                      <span
                        onClick={() => this.toggleTextEditor("deadline")}
                        className="text-optional"
                      >
                        {this.state.tempValue.deadline}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="row row-secondary">
                <span className="text-optional">
                  {!!branchId
                    ? "Color: " + branchId.replaceAll(",", " ・ ")
                    : null}
                  {!!patterns
                    ? "Pattern: " + patterns.replaceAll(",", " ・ ")
                    : null}
                </span>
              </div>
              <div className="row row-third">
                {this.state.textEditorOpened.material ? (
                  this.showReWriterInput("material")
                ) : (
                  <span
                    onClick={() => this.toggleTextEditor("material")}
                    className="text-optional"
                  >
                    {!!this.state.tempValue.material
                      ? `(${this.state.tempValue.material})`
                      : null}
                  </span>
                )}
              </div>
              <div className="row row-third">
                {this.state.textEditorOpened.info ? (
                  this.showReWriterInput("info")
                ) : (
                  <span
                    onClick={() => this.toggleTextEditor("info")}
                    className="text-optional"
                  >
                    {!!this.state.tempValue.info
                      ? this.state.tempValue.info
                      : null}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.cardType === "type2" && !this.props.hideStock) {
      return (
        <div
          className={`card img-thumbnail ${
            selected ? "selected-for-print" : ""
          } ${this.props.cardType}`}
        >
          {this.props.viewMode && (
            <div className="hover-show btn-arrow-wrapper ">
              <button
                className="btn btn-arrow"
                onClick={() => this.props.changeSelectedCardPositionFn("up")}
              >
                <i className="fa fa-arrow-circle-up" />
              </button>
              <button
                className="btn btn-arrow"
                onClick={() => this.props.changeSelectedCardPositionFn("down")}
              >
                <i className="fa fa-arrow-circle-down" />
              </button>
            </div>
          )}
          <button
            className={`btn hover-show btn-selected ${
              selected ? "active-for-print" : ""
            }`}
            onClick={(event) => {
              let target = event.currentTarget
                ? event.currentTarget
                : event.srcElement;
              let parentNode = target.parentNode;
              if (target.className.indexOf("active-for-print") === -1) {
                target.className += " active-for-print";
                parentNode.className += " selected-for-print";
                this.props.selectItemForPrintFn();
              } else {
                target.className = target.className.replace(
                  new RegExp("active-for-print", "g"),
                  ""
                );
                parentNode.className = parentNode.className.replace(
                  new RegExp("selected-for-print", "g"),
                  ""
                );
                this.props.deselectItemForPrintFn();
              }
            }}
          >
            <i className="icon-btn fa fa-check" />
          </button>
          <div className="card-img-top card-img-top_type2">
            <img
              className="card-img"
              src={this.props.imgUrl}
              alt={this.props.name}
              onLoad={(e) => this.props.imgLoadDetectFn(e)}
              onError={(e) => {
                e.hasError = true;
                e.errorMsg = this.props.imgUrl;

                this.props.imgLoadDetectFn(e);
              }}
            />
          </div>
          <div className="card-body">
            <h5 className="card-title text size-common text-bold">
              {this.props.name}
            </h5>
            <span
              className={`text text-bold size-common ${
                regular ? "text-color-primary" : ""
              }`}
            >
              {this.props.productId}
            </span>
            <span
              className={`text text-bold size-common margin2-left margin1-right ${
                regular ? "text-color-primary" : ""
              }`}
            >
              {(this.props.productId + "").indexOf(branchId) > -1
                ? ""
                : branchId}
            </span>
            {this.props.hidePrice ? (
              ""
            ) : (
              <span className="text size-common">
                {parseInt(this.props.price).toLocaleString("JP", {
                  style: "currency",
                  currency: "JPY",
                })}
                {this.props.lang === "ja" ? "(税別)" : "(Tax excluded)"}
              </span>
            )}
            {size ? (
              <p className="card-text card-text_code  size-smaller">
                <span className="label">Size: </span>
                <span className="text">{toHalfWidth(size)}</span>
              </p>
            ) : (
              ""
            )}
            {this.props.ct ? (
              <p className="card-text card-text_code size-smaller">
                <span className="label">CT / </span>
                <span className="text">
                  {this.props.ct} ({this.props.orderSize})
                </span>
              </p>
            ) : (
              ""
            )}
            {this.props.material ? (
              <p className="card-text card-text_code size-smaller short-lineheight">
                <span className="label">Material: </span>
                <span className="text">{toHalfWidth(this.props.material)}</span>
              </p>
            ) : (
              ""
            )}
            {this.props.stocks && this.props.checkMode === "stockCheck" ? (
              <p className="card-text card-text_code size-smaller">
                <span className="label">
                  {this.props.lang === "ja" ? "在庫数: " : "Stock: "}
                </span>
                <span className="text">{this.props.stocks}</span>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`card img-thumbnail ${
            selected ? "selected-for-print" : ""
          }`}
        >
          {this.props.viewMode && (
            <div className="hover-show btn-arrow-wrapper ">
              <button
                className="btn btn-arrow"
                onClick={() => this.props.changeSelectedCardPositionFn("up")}
              >
                <i className="fa fa-arrow-circle-up" />
              </button>
              <button
                className="btn btn-arrow"
                onClick={() => this.props.changeSelectedCardPositionFn("down")}
              >
                <i className="fa fa-arrow-circle-down" />
              </button>
            </div>
          )}
          <button
            className={`btn hover-show btn-selected ${
              selected ? "active-for-print" : ""
            }`}
            onClick={(event) => {
              let target = event.currentTarget
                ? event.currentTarget
                : event.srcElement;
              let parentNode = target.parentNode;
              if (target.className.indexOf("active-for-print") === -1) {
                target.className += " active-for-print";
                parentNode.className += " selected-for-print";
                this.props.selectItemForPrintFn();
              } else {
                target.className = target.className.replace(
                  new RegExp("active-for-print", "g"),
                  ""
                );
                parentNode.className = parentNode.className.replace(
                  new RegExp("selected-for-print", "g"),
                  ""
                );
                this.props.deselectItemForPrintFn();
              }
            }}
          >
            <i className="icon-btn fa fa-check" />
          </button>
          <div className="card-img-top">
            {!!this.state.openedBoxes[
              this.props.productId + this.props.branchId
            ] ? (
              <div className="popup-textfield popup-temp-imageUrl">
                <div
                  className="bg-shadow"
                  onClick={(e) => {
                    this.toggleTempImgUrlBox(
                      this.props.productId + this.props.branchId
                    );
                  }}
                />
                <div
                  className="button-close"
                  onClick={(e) => {
                    this.toggleTempImgUrlBox(
                      this.props.productId + this.props.branchId
                    );
                  }}
                >
                  <i className="fa fa-close" />
                </div>
                <div className="input-wrapper">
                  <input
                    className="input-image_url"
                    type="url"
                    placeholder="画像URLを記入"
                    value={
                      this.state.tempImages[
                        this.props.productId + this.props.branchId
                      ]
                    }
                    onChange={(e) =>
                      this.handleChangeInTempImageUrl(
                        e.target.value,
                        this.props.productId + this.props.branchId
                      )
                    }
                  />
                  <button
                    className="input-image_save"
                    onClick={(e) =>
                      this.closeTempImgUrlBox(
                        this.props.productId,
                        this.props.branchId
                      )
                    }
                  >
                    保存
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}

            <img
              className="card-img"
              src={this.props.imgUrl}
              alt={this.props.name}
              onLoad={(e) => this.props.imgLoadDetectFn(e)}
              onClick={(e) => {
                this.toggleTempImgUrlBox(
                  this.props.productId + this.props.branchId
                );
              }}
              onError={(e) => {
                e.hasError = true;
                e.errorMsg = this.props.imgUrl;

                this.props.imgLoadDetectFn(e);
              }}
            />
          </div>
          <div className="card-body px-2 py-3">
            <h5 className="card-title">
              <span className="text">{this.props.name}</span>
            </h5>
            {this.props.hidePrice ? (
              ""
            ) : (
              <p className="card-text card-text_price">
                <span className="text">
                  {this.props.adjustedPrice
                    ? this.props.adjustedPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : parseInt(this.props.price).toLocaleString("JP", {
                        style: "currency",
                        currency: "JPY",
                      })}
                </span>
              </p>
            )}
            <p
              className={`card-text card-text_code ${
                this.props.hideProductId ? "hideCardElement" : ""
              }`}
            >
              <span className="label">
                {this.props.lang === "ja" ? "商品コード: " : "Product code: "}
              </span>
              <span className="text">
                {this.props.productId}
                {(this.props.productId + "").indexOf(branchId) > -1
                  ? ""
                  : branchId}
              </span>
            </p>
            {size ? (
              <p className="card-text card-text_code">
                <span className="label">
                  {this.props.lang === "ja" ? "サイズ: " : "Size: "}
                </span>
                <span className="text">{toHalfWidth(size)}</span>
              </p>
            ) : (
              ""
            )}
            {material ? (
              <p className="card-text card-text_code">
                <span className="label">
                  {this.props.lang === "ja" ? "素材: " : "Material: "}
                </span>
                <span className="text">{toHalfWidth(material)}</span>
              </p>
            ) : (
              ""
            )}
            {this.state.textEditorOpened.memo ? (
              this.showReWriterInput("memo")
            ) : (
              <p
                className="card-text card-text_code editable-field-wrapper"
                onClick={() => this.toggleTextEditor("memo")}
              >
                {this.state.tempValue.memo ? (
                  <>
                    <span className="label">
                      {this.props.lang === "ja" ? "メモ: " : "Memo: "}
                    </span>
                    <span className="text">{this.state.tempValue.memo}</span>
                  </>
                ) : (
                  ""
                )}
              </p>
            )}
            <ul
              className={`list-horizontal ${this.props.cardType} ${
                this.props.hideStock ? "onlyShowOrderSize" : ""
              }`}
            >
              <li className="list first">
                <p className="label">CT</p>
                <p className="text">{this.props.ct}</p>
              </li>
              <li className="list middle">
                <p className="label">
                  {this.props.lang === "ja" ? "発注単位" : "Order unit"}
                </p>
                <p className="text">{this.props.orderSize}</p>
              </li>
              <li className="list last">
                <p className="label">
                  {this.props.lang === "ja" ? "在庫数" : "Stock"}
                </p>
                <p className="text">{this.props.stocks|| 0} </p>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }
}
